:root {
  --header-height: 82px;
}

.iframe {
  flex-grow: 1;
  height: calc(100vh - var(--header-height));
  border: none;
}

.ant-modal-content {
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
