body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper {
  display: flex;
  flex-direction: column;
  background: #1f1f1f;
}

.header {
  -webkit-text-stroke-color: #2c3e50;
  padding: 0 2rem;
  text-align: center;
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 4px;
  text-transform: uppercase;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  color: #ffffff;

  @media (max-width: 1024px) {
    font-size: 1rem;
  }
}

.button {
  border: none;
  background: #2c3e50;
  color: #ecf0f1;
  padding: 1rem 2rem;
  font-size: 1rem;
  border-radius: 2rem;
  font-weight: 700;
  letter-spacing: 2px;

  @media (max-width: 1024px) {
    font-size: 0.75rem;
    padding: 1rem 1rem;
  }

  @media (max-width: 600px) {
    font-size: 0.5rem;
  }
}
